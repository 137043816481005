/*CSS para la parte del usuario*/
.containerUser{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
}

.cantainer-user{
  width: 320px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-name{
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-user{
  color: #67B4C0;
  font-weight: bold;
}

.logo-user, .logo-logout{
  width: 50px;
  height: 50px;
}
/* Estilo para los user-img */
.custom-image {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Hace que la imagen sea redonda */
  object-fit: cover; /* Ajusta la imagen para que cubra todo el contenedor */
}
/*FIN*/

/* CSS para el logo*/
.logo-header{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
}

.logo-header-box{
  width: 200px;
  height: 80px;
}

.logo-img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/*FIN*/