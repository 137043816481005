:root{
    --primary: #283549;
    --font: #67B4C0;
    --grey: #efefef;
}
.socio{
    color:var(--font);
    font-weight: bold;
}
#cajanav{  
    background-color: #fcfcfc;
    font-weight: bold;
    font-size: 11pt; text-align: right;
}
#cajanav:hover{
    border-top-left-radius: 0;
}
.button{
    background-color: #fcfcfc;
    font-weight: bold;
    font-family: 11pt; text-align: right;
    border-color: transparent; 
}
.button:focus{
   border:0;
}
#input{
background-color: #fcfcfc;
    font-weight: bold;
    font-size: 11pt; text-align: right;
}
@media screen and (max-width: 768px) {
    .prueba{
        display: none;
    }
}

/* Estilo personalizado para el botón */
.btn-custom {
    background-color: var(--primary); /* Color de fondo personalizado */
    color: var(--font); /* Color del texto en el botón (opcional) */
    
    /* Agrega otros estilos personalizados según sea necesario */
  }
  
  /* Cambia el color del texto del botón cuando se hace hover (opcional) */
  .btn-custom:hover {
    background-color: var(--primary); /* Color de fondo personalizado en hover */
    opacity: 0.8;
    color: #efefef;
    /* Cambia otros estilos en hover si es necesario */
  }
  
/* Estilo para hacer que el botón sea circular */
.btn-rounded {
    width: 50px; /* Ancho deseado */
    height: 50px; /* Alto deseado */
    border-radius: 50%; /* Hace que el botón sea circular */
    border: none;
  }
  
  /* Cambio de estilo en hover (opcional) */
.btn-rounded:hover {
    /* Agrega aquí los estilos en hover si lo deseas */
    opacity: 0.8;
  }
  
.filter{
    width: 100%;
}
.item{
    margin-left: 10px;
  }
.custom-add-socio{
    display: flex;
    justify-content: flex-end;
}