:root{
    --primary: #283549;
    --font: #67B4C0;
    --white: white;
    --grey: #efefef;
}
#sidebar-container{
     min-height: 100px;
     height: calc(100vh - 110px);
}
#sidebar-container .menu{
    min-width: 100%;
}
.bg-primary {
    background-color: var(--primary) !important;
}
#bloque:hover{
    text-decoration:none;
    background-color: var(--font);
    color: var(--white);
   
} 
#bloque{
    color:var(--font);
    text-decoration:none;
    text-align:start;
    height: 70px;
    padding-left: 50px;
    padding-top: 16px;
    padding-bottom: 16px;
}