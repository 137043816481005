:root{
    --primary: #283549;
    --font: #67B4C0;
    --grey: #efefef;
}
.custom-padding{
    padding-left: 0px;
    padding-right: 0px;
}

.custom-container {
    padding: 10px;
  }
.custom-scroll{
    background-color: var(--primary);
}

.custom-fondo {
    background-image: url('./images/fondo.png');
    background-size: auto 100%; /* Ajusta la imagen a la altura y mantiene el ancho original */
    background-repeat: no-repeat; /* Evita la repetición de la imagen de fondo */
    background-position: center center; /* Centra la imagen horizontal y verticalmente */
    /* Otras propiedades de estilo opcionales */
}
  

/* Estilos para los elementos internos de Conteiner */
.elemento-interno{
    padding: 10px; /* Agrega el padding deseado, por ejemplo, 10px */
    /* Otros estilos si es necesario */
    /* Otras propiedades de estilo opcionales */
}
  
.navigation{
    position: relative;
    padding-bottom: 0px;
    padding-top: 0px;
}

.global-container{
    padding: 0;
    margin: 0;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    
    /*overflow: hidden; /* Oculta el scroll vertical */
}
.conteiner-var{
    padding: 0;
    margin: 0;
    min-height: calc(100% - 50px - 50px);
}

.diplay-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.container-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Altura del contenedor (puedes ajustarlo según tus necesidades) */
    
  }

  .box-top {
    height: 80px; /* Altura del elemento superior (fijo) */
    align-items: center;
  }

  .box-middle {
    flex-grow: 1; /* Permite que el elemento se adapte al espacio disponible */
  }

  .box-bottom {
    height: 40px; /* Altura del elemento inferior (fijo) */
  }