.custom-padding {
    padding-left: 0;
    padding-right: 0;
}
.container-global{
    width: 500px;
    height: 250px; /* Altura automática para adaptarse al contenido */
    margin: 1%; /* Margen del 1% en todas las direcciones */
    border: 1px solid transparent;
    /* Aplicar sombra solo en la parte inferior */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    display: flex;
}

.container-child{
    width: 250px;
    height: 250px;
    flex: 1;
}
.zona1{
    height: 70px;
}
.zona1 label{
    font-size: 20px;
    font-weight: bold;
}
.denominacion{
    align-items: center;
    margin-left: 20px;
    margin-top: 30px;
}
.descripcion{
    margin-left: 30px;
    align-items: center;
    height: 120px;
}
.zona2{
    height: 120px;
}
.zona3{
    height: 60px;
}



/*CSS para los iconos*/
.custom-icon,
.custom-icon-edit,
.custom-icon-delete {
    height: 30px; /* Altura fija para los iconos */
    width: 30px; /* Ancho fijo para los iconos */
    border-radius: 50%; /* Hace que la imagen sea redonda */
}

.custom-icon-delete:hover, .custom-icon-edit:hover, .custom-icon:hover {
    opacity: 50%;
    z-index: 1;
}

.container-actions{
    width: 250px;
    height: 100%;
    display: flex;
    bottom: 0;
    align-items: center;
}
.container-action{
    width: 250px;
    height: 60px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-icons{
    width: 110px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/*FIN*/

/*CSS para el logo usuario*/
.container-logo{
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo{
    height: 250px;
    width: 250px; 

    /*box-shadow: 1px 1px rgba(0, 0, 0, 0.3);*/
}

.img_logo{
    max-width: 100%;
    height: 100%;
    object-fit: contain;
}
/*FIN*/